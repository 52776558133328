import {
  CellId,
  DELETE_CELL,
  DeleteOriginMetadata,
  filterSortedCellsChildren,
  flattenSortedCells,
  setDifference,
} from "@hex/common";
import { useCallback } from "react";

import { DispatchAOResult } from "../../atomic-operations/AtomicOperationController.js";
import { useStore } from "../../redux/hooks.js";
import { dereferenceSqlBlockCellChildId } from "../../redux/slices/cell-location/index.js";
import { hexVersionMPSelectors } from "../../redux/slices/hexVersionMPSlice.js";
import { cellSelectionSelectors } from "../../redux/slices/logicViewSlice.js";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext.js";
import { useProjectContext } from "../../util/projectContext.js";
import { getValueFromReduxOrStorage } from "../useBrowserStorage.js";
import { LocalStorageKeys } from "../useLocalStorage.js";

import { useSelectCell } from "./useSelectCell.js";

export function useDeleteCells(): (
  cellsToDelete: CellId[],
  origin?: DeleteOriginMetadata,
) => DispatchAOResult {
  const store = useStore();
  const { hexVersionId } = useProjectContext();
  const { dispatchAO } = useHexVersionAOContext();

  const { selectCells } = useSelectCell();

  return useCallback(
    (cellIdsToDelete, origin) => {
      const cellIdsToDeleteSet = new Set(cellIdsToDelete);

      const operations = cellIdsToDelete.map((cellId) =>
        DELETE_CELL.create({ cellId, origin }),
      );
      const state = store.getState();
      const selection = cellSelectionSelectors.selectSelectedCellIdSet(state);

      const possibleSelectionAfter = setDifference(
        selection,
        cellIdsToDeleteSet,
      );

      // update selection before deleting, otherwise we might not be able to
      // update the selection based on the previous state
      if (selection.size > 0) {
        if (possibleSelectionAfter.size > 0) {
          selectCells(Array.from(possibleSelectionAfter));
        } else {
          // if the selection will be empty, go ahead and
          // pick a reasonable cell to select

          const sortedCells = flattenSortedCells(
            filterSortedCellsChildren(
              hexVersionMPSelectors
                .getCellContentAwareSelectors(hexVersionId)
                .selectSortedCellsWithoutSQLBlockChildren(state),
              (cell) =>
                !getValueFromReduxOrStorage(
                  store.getState(),
                  LocalStorageKeys.CELL_SOURCE_COLLAPSED(cell.id),
                  "Local",
                ),
            ),
          );

          const firstCellIdToDelete_ = cellIdsToDelete[0];
          const firstCellIdToDelete = dereferenceSqlBlockCellChildId({
            state,
            hexVersionId,
            cellId: firstCellIdToDelete_,
          });
          const lastCellIdToDelete_ =
            cellIdsToDelete[cellIdsToDelete.length - 1];
          const lastCellIdToDelete = dereferenceSqlBlockCellChildId({
            state,
            hexVersionId,
            cellId: lastCellIdToDelete_,
          });

          const sortedCellsBeforeDeletionRange = [];
          const sortedCellsAfterDeletionRange = [];

          let beforeRange = true;
          let afterRange = false;
          for (const cell of sortedCells) {
            if (cell.id === firstCellIdToDelete) {
              beforeRange = false;
            }

            if (beforeRange) {
              sortedCellsBeforeDeletionRange.push(cell);
            } else if (afterRange) {
              sortedCellsAfterDeletionRange.push(cell);
            }

            if (cell.id === lastCellIdToDelete) {
              afterRange = true;
            }
          }

          if (sortedCellsAfterDeletionRange.length > 0) {
            selectCells(sortedCellsAfterDeletionRange[0].id);
          } else if (sortedCellsBeforeDeletionRange.length > 0) {
            selectCells(
              sortedCellsBeforeDeletionRange[
                sortedCellsBeforeDeletionRange.length - 1
              ].id,
            );
          } else {
            selectCells(undefined);
          }
        }
      }

      return dispatchAO(operations);
    },
    [dispatchAO, hexVersionId, selectCells, store],
  );
}
