import { ParameterName, notEmpty } from "@hex/common";
import { useCallback } from "react";

import { useCellsContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";

/**
 * get a unique name for a new input cell, first trying the provided name
 * if specified
 */
export function useGetUniqueInputCellName(): (
  /**
   * pass if you want the new cell's name to be based off of some previous name
   */
  name?: string,
  /**
   * pass if you're generating names for multiple cells in a loop (to avoid the same name being generated each time)
   */
  extraExistingNames?: ParameterName[],
) => ParameterName {
  const getCellsContents = useCellsContentsGetter();

  return useCallback(
    (name, extraExistingNames) => {
      const inputCells = Object.values(getCellsContents())
        .filter(notEmpty)
        .flatMap((c) => (c.__typename === "Parameter" ? c : []));

      const existingInputNames = new Set(
        inputCells.map((inputCell) => inputCell.name),
      );

      extraExistingNames?.forEach((existingName) => {
        existingInputNames.add(existingName);
      });

      let count = 0;
      let testName = name;
      if (name == null) {
        count++;
        testName = `input_${count}`;
      }
      while (existingInputNames.has(testName as ParameterName)) {
        count++;
        testName = `input_${count}`;
      }

      return testName as ParameterName;
    },
    [getCellsContents],
  );
}
